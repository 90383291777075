export const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const LOT_QUERY = `${SERVER_URL}/lot`
export const FACILITY_QUERY = `${SERVER_URL}/facility`
export const VARIETY_QUERY = `${SERVER_URL}/variety`
export const GROWER_QUERY = `${SERVER_URL}/grower`
export const DEFECT_QUERY = `${SERVER_URL}/defect`
export const LOCATION_QUERY = `${SERVER_URL}/location`
export const CATEGORY_QUERY = `${SERVER_URL}/category`
export const UNIT_QUERY = `${SERVER_URL}/unit`
export const REGION_QUERY = `${SERVER_URL}/region`
export const OUTLET_QUERY = `${SERVER_URL}/outlet`
export const SIZE_QUERY = `${SERVER_URL}/size`
export const USER_QUERY = `${SERVER_URL}/user`
export const ROLE_QUERY = `${SERVER_URL}/role`
export const SAMPLE_QUERY = `${SERVER_URL}/sample`
export const COLOR_QUERY = `${SERVER_URL}/color`
export const STEM_CONDITION_QUERY = `${SERVER_URL}/stemcondition`
export const SAMPLE_TYPE_QUERY = `${SERVER_URL}/sampletype`
export const SHIFT_QUERY = `${SERVER_URL}/shift`

export const CATEGORY_TYPE_QUERY = `${SERVER_URL}/categorytype`
export const UNIT_TYPE_QUERY = `${SERVER_URL}/unittype`
export const FACILITIES_LOCATIONS_QUERY = `${SERVER_URL}/facilitieslocations`
export const CATEGORIES_DEFECTS_QUERY = `${SERVER_URL}/categoriesdefects`
export const LOCATIONS_DEFECTS_QUERY = `${SERVER_URL}/locationsdefects`
export const LOTS_VARIETIES_QUERY = `${SERVER_URL}/lotsvarieties`
export const LOTS_FACILITIES_QUERY = `${SERVER_URL}/lotsfacilities`
export const CATEGORIES_VARIETIES_QUERY = `${SERVER_URL}/categoriesvarieties`
export const UNITS_SIZES_QUERY = `${SERVER_URL}/unitssizes`
export const OUTLETS_SIZES_QUERY = `${SERVER_URL}/outletssizes`
export const VARIETIES_SIZES_QUERY = `${SERVER_URL}/varietiessizes`
export const VARIETIES_DEFECTS_QUERY = `${SERVER_URL}/varietiesdefects`
export const VARIETIES_STEM_CONDITIONS_QUERY = `${SERVER_URL}/varietiesstemconditions`
export const VARIETIES_COLORS_QUERY = `${SERVER_URL}/varietiescolors`
export const VARIETIES_SAMPLE_TYPE_QUERY = `${SERVER_URL}/varietiessampletypes`

export const STAFF_PERFORMANCE_SAMPLES_QUERY = `${SERVER_URL}/sample/staffperformance`
export const RECEIVING_SUMMARY_QUERY = `${SERVER_URL}/sample/receivingsummary2`
export const DELETE_RECEIVING_SUMMARY = `${SERVER_URL}/sample/DeleteReceivingSummary`
export const INSERT_BIN_WEIGHT_RECEIVING_SUMMARY = `${SERVER_URL}/sample/insertBinWeightReceivingSummary`
export const SEND_EMAIL_REPORT = `${SERVER_URL}/sample/sendEmailReport`

export const QUALITY_SUMMARY_QUERY = `${SERVER_URL}/sample/qualitysummary`
export const SAMPLE_SIZE_DISTRIBUTION_RECEIVING_UPSTREAM_QUERY = `${SERVER_URL}/sample/sizedistributionforreceivingandupstream`
export const SAMPLE_SIZE_DISTRIBUTION_INLINE_FINISHED_BOX_QUERY = `${SERVER_URL}/sample/sizedistributionforinlineandfinishedbox`
export const DEFECTS_SUMMARY_QUERY = `${SERVER_URL}/sample/defectssummary`
export const ACCOUNT_LOGIN_QUERY = `${SERVER_URL}/account/loginv2`
// export const ACCOUNT_LOGIN_QUERY = `${SERVER_URL}/account/loginv2`
export const ACCOUNT_REFRESH_TOKEN_QUERY = `${SERVER_URL}/account/refreshtokenv2`
export const USERS_FACILITIES_QUERY = `${SERVER_URL}/usersfacilities`
export const AB_SUBSCRIPTION_QUERY = `${SERVER_URL}/asbsubscription`
export const SAMPLE_DELETED_HISTORY_QUERY = `${SERVER_URL}/sampledeletedhistory`
export const DASHBOARD_QUERY = `${SERVER_URL}/dashboard/GetDashboardByDateRange`
export const DASHBOARDV2_QUERY = `${SERVER_URL}/dashboard/GetDashboardByDateRangeV2`
export const DOWNTIME_QUERY = `${SERVER_URL}/downtime`
export const UPDATE_ROLES_USER = `${SERVER_URL}/user/updaterolesuser`
export const PACKTYPE_QUERY = `${SERVER_URL}/packtype`
export const PACKTYPES_FACILITIES_QUERY = `${SERVER_URL}/packtypesfacilities`
export const PACKTYPES_VARIETIES_QUERY = `${SERVER_URL}/packtypesvarieties`
export const LOGS_QUERY = `${SERVER_URL}/sample/GetLogsSamplesSubmitted`
export const ALERT_CONFIG_QUERY = `${SERVER_URL}/alertconfig`
export const PRODUCTION_SUMMARY_QUERY = `${SERVER_URL}/sample/productionSummary`
export const USERS_SHIFT_QUERY = `${SERVER_URL}/usersshifts`
export const INTEGRATEDREPORT_QUERY = `${SERVER_URL}/sample/IntegratedReport`
export const MACHINE_QUERY = `${SERVER_URL}/machine`
export const MACHINES_FACILITY_QUERY = `${SERVER_URL}/machinesfacility`
export const DAILY_AVERAGE_DASHBOARD_QUERY = `${SERVER_URL}/dashboard/getdailyaveragedashboard`
export const QUALITYMATRIX_QUERY = `${SERVER_URL}/sample/QualityMatrix`
export const SIZINGMATRIX_QUERY = `${SERVER_URL}/sample/SizingMatrix`
export const DEFECT_BY_NAME_SUMMARY_QUERY = `${SERVER_URL}/sample/DefectByNameSummary`
export const PACK_TYPE_REPORT_QUERY = `${SERVER_URL}/sample/packTypeReport`
export const GRADE_BY_SIZE = `${SERVER_URL}/sample/gradeBySizeSummary`
export const GROWER_SUMMARY = `${SERVER_URL}/sample/growerSummary`
export const LOTTIME_QUERY = `${SERVER_URL}/lottime`
export const GET_SAMPLE_FIELD = `${SERVER_URL}/sampleCreator`
export const NOTIFICATION_QUERY = `${SERVER_URL}/notification`
export const INACTIVE_LOG_QUERY = `${SERVER_URL}/sample/InactiveLog`
export const ACTIVE_LOG_QUERY = `${SERVER_URL}/sample/ActiveLog`
export const TEMPLATELOCATION_QUERY = `${SERVER_URL}/templatelocation`
export const LOCK_USER = `${SERVER_URL}/user/Lock`
export const UNLOCK_USER = `${SERVER_URL}/user/UnLock`
export const BIN_SUMMARY_QUERY = `${SERVER_URL}/sample/binSummary`
export const PACKMAN_QUERY = `${SERVER_URL}/packman`
export const PACKED_INVENTORY = `${SERVER_URL}/sample/insertPackedInventory`
export const UPLOAD_PACKMAN_QUERY = `${SERVER_URL}/storage/`
export const UPLOAD_PACKMAN_TRACK_VIDEO_QUERY = `${SERVER_URL}/videoAnalysis/`
